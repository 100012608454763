import axios, { type AxiosInstance } from "axios";

let http: AxiosInstance;


export function getHttpClient() {
  if (!http) {
    const apiUrl = process.env.API_URL ?? process.env.NEXT_PUBLIC_API_URL;
    
    if (!apiUrl)
      throw new Error(`NEXT_PUBLIC_API_URL is not defined`);
  
    http = axios.create({
      baseURL: apiUrl
    });
  }

  return http;
}