import * as Sentry from '@sentry/nextjs';

type AdditionalSentryData = Record<string, Record<string, string | boolean | number>>;

export function captureError(error: unknown, additionalData?: AdditionalSentryData) {
  if (process?.env?.SENTRY_ENV === 'local') {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  Sentry.withScope(scope => {
    if (additionalData)
      Object.entries(additionalData).map(([key, value]) => {
        scope.setContext(key, value);
      });
      
    Sentry.captureException(error);
  });
}